import React from "react";
import "./BRSRNotesAction.scss";
import { Tooltip } from "antd";

const BRSRNotesAction = ({ questionId, questionData, onAddNote, activeQuestionId }) => {
  const handleAddNote = () => {
    if (onAddNote) {
      onAddNote(questionId);
    }
  };

  // Check if the editor is currently open for this question
  const isEditorOpen = activeQuestionId === questionId;

  return (
    <>
      {!questionData?.note && !questionData?.isApproved && questionData?.isAnswered && !isEditorOpen && (
        <div onClick={handleAddNote} className="cursor-pointer">
          <div className="notes-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M11.9474 1.5V3M8.26316 1.5V3M4.57895 1.5V3M14.5263 7.5C14.5263 5.025 14.5263 3.7875 13.7711 3.01875C13.0158 2.25 11.8 2.25 9.36842 2.25H7.15789C4.72632 2.25 3.51053 2.25 2.75526 3.01875C2 3.7875 2 5.025 2 7.5V11.25C2 13.725 2 14.9625 2.75526 15.7313C3.51053 16.5 4.72632 16.5 7.15789 16.5H9.36842M13.0526 10.5V16.5M16 13.5H10.1053M5.31579 11.25H8.26316M5.31579 7.5H11.2105"
                stroke="#1F93EF"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="content">
              <p className="m-0">Add Notes</p>
            </div>
          </div>
        </div>
      )}
      {!questionData?.isAnswered && (
        <Tooltip
          placement="topLeft"
          title={`You can add notes only after answering the question`}
          overlayStyle={{
            color: "#FFF",
            fontSize: "0.75rem",
            fontWeight: "400",
            whiteSpace: "pre-line"
          }}
        >
          <div className="disabled-note-action">
            <div className="notes-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M11.9474 1.5V3M8.26316 1.5V3M4.57895 1.5V3M14.5263 7.5C14.5263 5.025 14.5263 3.7875 13.7711 3.01875C13.0158 2.25 11.8 2.25 9.36842 2.25H7.15789C4.72632 2.25 3.51053 2.25 2.75526 3.01875C2 3.7875 2 5.025 2 7.5V11.25C2 13.725 2 14.9625 2.75526 15.7313C3.51053 16.5 4.72632 16.5 7.15789 16.5H9.36842M13.0526 10.5V16.5M16 13.5H10.1053M5.31579 11.25H8.26316M5.31579 7.5H11.2105"
                  stroke="#1F93EF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="content">
                <p className="m-0">Add Notes</p>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default BRSRNotesAction;
