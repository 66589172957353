import React, { useCallback, useState } from "react";
import "./SearchBar.scss";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ placeHolder, onSearch = () => {}, noFlex, style, searchWidth}) => {
  const [searchText, setSearchText] = useState("");
  const [showClear, setShowClear] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      await onSearch(value);
    }, 500),
    []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    setShowClear(!!value);
    debouncedSearch(value);
  };

  const handleClear = () => {
    setSearchText("");
    setShowClear(false);
    onSearch("");
  };

  return (

      <div className="invite-container" style={noFlex ? { flex: 0, ...style } : {...style}}>
        <input
          type="text"
          className="email-input"
          placeholder={placeHolder}
          onChange={handleChange}
          value={searchText}
          style={searchWidth ? {width: searchWidth}: {}}
        />
        {showClear ? (
          <p className="cursor-pointer" onClick={handleClear}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22" fill="none">
              <path
              d="M6.6665 13.3334L13.3332 6.66675M13.3332 13.3334L6.6665 6.66675"
              stroke="black"
              stroke-opacity="0.6"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            </svg>
          </p>
        ) : (
          <SearchIcon />
        )}
      </div>
  );
};

export default SearchBar;
