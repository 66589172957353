export const FIELD_HIERARCHY = [
  "scope",
  "category",
  "subCategory",
  "method",
  "emissionSource",
  "emissionSubSource",
  "emissionAttribute",
  "operationalActivity",
  "uom"
];

export const anomalyFormData = {
  scopes: ["Scope 1", "Scope 2", "Scope 3"],

  // Scope -> Categories
  "Scope 1": ["Stationary", "Mobile Emissions", "Fugitive Emissions", "Gas Flaring / Venting"],
  "Scope 2": ["Purchased Electricity", "Purchased Heat and Steam"],
  "Scope 3": [
    "Transportation",
    "Waste Management",
    "Employee Commuting",
    "Capital Goods",
    "Investments",
    "Franchises",
    "Leased Assets",
    "Business Travel",
    "Fuel & Energy",
    "Upstream T&D",
    "Downstream T&D",
    "Processing of Sold Products"
  ],

  // Categories -> Subcategories
  Stationary: ["Coal Combustion", "Natural Gas", "Diesel Generators"],
  "Mobile Emissions": ["Fleet Vehicles", "Heavy Machinery", "Aviation Fuel"],
  "Fugitive Emissions": ["Refrigerants", "Process Leaks", "Pipeline Leaks"],
  "Gas Flaring / Venting": ["Offshore Flaring", "Onshore Flaring", "Venting"],
  "Purchased Electricity": ["Grid Electricity", "Renewable Power", "Nuclear Power"],
  "Purchased Heat and Steam": ["District Heating", "Industrial Boilers", "Cogeneration"],
  Transportation: ["Freight Transport", "Passenger Vehicles", "Marine Shipping"],
  "Waste Management": ["Landfills", "Waste Incineration", "Recycling Facilities"],
  "Employee Commuting": ["Public Transport", "Company Vehicles", "Remote Work"],
  "Capital Goods": ["Manufacturing Equipment", "IT Hardware", "Office Furniture"],

  // Subcategories -> Methods
  "Coal Combustion": ["Energy Based", "Carbon Content"],
  "Natural Gas": ["Energy Based", "Fuel Based"],
  "Diesel Generators": ["Fuel Based", "Carbon Content"],
  "Fleet Vehicles": ["Energy Based", "Fuel Based"],
  "Heavy Machinery": ["Fuel Based"],
  "Aviation Fuel": ["Carbon Content"],
  Refrigerants: ["Leakage Factor"],
  "Process Leaks": ["Leakage Factor"],
  "Pipeline Leaks": ["Leakage Factor"],
  "Offshore Flaring": ["Emission Factor"],
  "Onshore Flaring": ["Emission Factor"],
  Venting: ["Emission Factor"],

  // Methods -> Emission Sources
  "Energy Based": ["Coal", "Natural Gas", "Diesel", "Petrol"],
  "Carbon Content": ["Direct Measurement", "Lab Analysis", "Default Values"],
  "Fuel Based": ["Consumption Data", "Purchase Records", "Meter Reading"],
  "Leakage Factor": ["Equipment Specs", "Monitoring Data", "Industry Standards"],
  "Emission Factor": ["Country Specific", "IPCC Default", "Custom Factor"],

  // Emission Sources -> Emission Sub Sources
  Coal: ["Anthracite", "Bituminous", "Lignite"],
  "Natural Gas": ["Pipeline", "LNG", "CNG"],
  "Direct Measurement": ["CEMS", "Portable Analyzer", "Manual Testing"],
  "Equipment Specs": ["Manufacturer Data", "Design Specifications", "Rating Plates"],
  "Country Specific": ["National Registry", "Local Guidelines", "Regional Factors"],

  // Emission Sub Sources -> Emission Attributes
  Anthracite: ["Carbon Content", "Heating Value", "Density"],
  Pipeline: ["Methane Content", "CO2 Content", "Pressure"],
  CEMS: ["Flow Rate", "Concentration", "Temperature"],
  "Manufacturer Data": ["Design Efficiency", "Operating Parameters", "Maintenance Factors"],
  "National Registry": ["Emission Factors", "Conversion Rates", "Default Values"],

  // Emission Attributes -> Operational Activities
  "Carbon Content": ["Lab Analysis", "Literature Values", "Industry Standards"],
  "Methane Content": ["Gas Chromatography", "Online Analyzer", "Periodic Testing"],
  "Flow Rate": ["Continuous Monitoring", "Batch Measurement", "Calculated Values"],
  "Design Efficiency": ["Nameplate Data", "Performance Testing", "Operating Conditions"],
  "Emission Factors": ["Published Values", "Calculated Factors", "Verified Data"],

  // Operational Activities -> UOM
  "Lab Analysis": ["tCO2e", "kgCO2e", "gCO2e"],
  "Gas Chromatography": ["mol%", "vol%", "mass%"],
  "Continuous Monitoring": ["m3/hr", "kg/hr", "ton/day"],
  "Nameplate Data": ["kW", "HP", "BTU/hr"],
  "Published Values": ["kgCO2e/unit", "tCO2e/unit", "gCO2e/unit"]
};

// Buffer options for min/max values
export const bufferOptions = [
  { value: "5", label: "5%" },
  { value: "10", label: "10%" },
  { value: "15", label: "15%" },
  { value: "20", label: "20%" },
  { value: "25", label: "25%" },
  { value: "30", label: "30%" }
];

// Period options
export const periodOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" }
];

// Status options
export const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" }
];

// Simpler helper function for flat structure
export const getOptionsForField = (fieldName, parentValue) => {
  if (fieldName === "scope") {
    return anomalyFormData.scopes;
  }

  if (!parentValue) return [];
  return anomalyFormData[parentValue] || [];
};

// Helper function to get the parent field name
export const getParentField = (fieldName) => {
  const currentIndex = FIELD_HIERARCHY.indexOf(fieldName);
  return currentIndex > 0 ? FIELD_HIERARCHY[currentIndex - 1] : null;
};

export const getAllAnomalyList = {
  data: [
    {
      _id: "67a1fb2173ebdedc99a2ff08",
      ruleName: "Propane Check 3",
      facility: [
        {
          _id: "65afd747a88851b5b987d268",
          name: "Battiprolu Processing Plant"
        },
        {
          _id: "6719e4b8612d494d1ef49cc0",
          name: "Bayyavaram Processing Plant"
        }
      ],
      organization: "65afce3f68915901427b0b0e",
      scope: {
        _id: "64fc7ff893fd1158f639d013",
        name: "scope 1"
      },
      category: {
        _id: "64fff3ca1e3b32af1f756f61",
        name: "Stationary Combustion"
      },
      subcategory: {
        _id: "64fff9190e43038a5ef06558",
        name: "Gaseous Fuels"
      },
      source: "PROPANE",
      subsource: "NA",
      attribute: "NA",
      unit: {
        _id: "64fc026c5228cac66a909ae3",
        name: "litres"
      },
      ruleApplicability: "monthly",
      startYear: 2024,
      endYear: 2025,
      minValue: 10,
      minBuffer: 5,
      maxValue: 100,
      maxBuffer: 10,
      blockOnAnomaly: false,
      status: "draft",
      isDeleted: false,
      resolvedCount: 0,
      unresolvedCount: 0,
      createdAt: "2025-02-04T11:33:53.096Z",
      updatedAt: "2025-02-04T12:15:34.849Z",
      __v: 0,
      anomalyDataCount: 0
    },
    {
      _id: "67a1f8b8c898b6f0d1361adc",
      ruleName: "Propane Check",
      facility: [
        {
          _id: "65afd747a88851b5b987d268",
          name: "Battiprolu Processing Plant"
        },
        {
          _id: "6719e4b8612d494d1ef49cc0",
          name: "Bayyavaram Processing Plant"
        }
      ],
      organization: "65afce3f68915901427b0b0e",
      scope: {
        _id: "64fc7ff893fd1158f639d013",
        name: "scope 1"
      },
      category: {
        _id: "64fff3ca1e3b32af1f756f61",
        name: "Stationary Combustion"
      },
      subcategory: {
        _id: "64fff9190e43038a5ef06558",
        name: "Gaseous Fuels"
      },
      source: "PROPANE",
      subsource: "NA",
      attribute: "NA",
      unit: {
        _id: "64fc026c5228cac66a909ae3",
        name: "litres"
      },
      ruleApplicability: "monthly",
      startYear: 2023,
      endYear: 2024,
      minValue: 10,
      minBuffer: 5,
      maxValue: 100,
      maxBuffer: 10,
      blockOnAnomaly: false,
      status: "active",
      isDeleted: false,
      resolvedCount: 0,
      unresolvedCount: 0,
      createdAt: "2025-02-04T11:23:36.991Z",
      updatedAt: "2025-02-04T11:23:36.991Z",
      __v: 0,
      anomalyDataCount: 0
    },
    {
      _id: "67a1f8b8c898b6f0d1361ad3",
      ruleName: "Electricity Consumption Monitor",
      facility: [
        {
          _id: "65afd747a88851b5b987d269",
          name: "Chennai Manufacturing Unit"
        }
      ],
      organization: "65afce3f68915901427b0b0e",
      scope: {
        _id: "64fc7ff893fd1158f639d014",
        name: "scope 2"
      },
      category: {
        _id: "64fff3ca1e3b32af1f756f62",
        name: "Purchased Electricity"
      },
      subcategory: {
        _id: "64fff9190e43038a5ef06559",
        name: "Grid Electricity"
      },
      source: "ELECTRICITY",
      subsource: "GRID",
      attribute: "CONSUMPTION",
      unit: {
        _id: "64fc026c5228cac66a909ae4",
        name: "kWh"
      },
      ruleApplicability: "daily",
      startYear: 2024,
      endYear: 2025,
      minValue: 1000,
      minBuffer: 10,
      maxValue: 5000,
      maxBuffer: 15,
      blockOnAnomaly: true,
      status: "active",
      isDeleted: false,
      resolvedCount: 5,
      unresolvedCount: 2,
      createdAt: "2024-12-01T10:23:36.991Z",
      updatedAt: "2024-12-01T10:23:36.991Z",
      __v: 0,
      anomalyDataCount: 7
    },
    {
      _id: "67a1f8b8c898b6f0d1361ad4",
      ruleName: "Business Travel Emissions",
      facility: [
        {
          _id: "65afd747a88851b5b987d270",
          name: "Corporate Office"
        },
        {
          _id: "65afd747a88851b5b987d271",
          name: "Regional Office"
        }
      ],
      organization: "65afce3f68915901427b0b0e",
      scope: {
        _id: "64fc7ff893fd1158f639d015",
        name: "scope 3"
      },
      category: {
        _id: "64fff3ca1e3b32af1f756f63",
        name: "Business Travel"
      },
      subcategory: {
        _id: "64fff9190e43038a5ef06560",
        name: "Air Travel"
      },
      source: "FLIGHTS",
      subsource: "DOMESTIC",
      attribute: "DISTANCE",
      unit: {
        _id: "64fc026c5228cac66a909ae5",
        name: "km"
      },
      ruleApplicability: "quarterly",
      startYear: 2024,
      endYear: 2025,
      minValue: 5000,
      minBuffer: 20,
      maxValue: 50000,
      maxBuffer: 25,
      blockOnAnomaly: false,
      status: "inactive",
      isDeleted: false,
      resolvedCount: 8,
      unresolvedCount: 3,
      createdAt: "2024-11-15T14:30:00.000Z",
      updatedAt: "2024-11-15T14:30:00.000Z",
      __v: 0,
      anomalyDataCount: 11
    },
    {
      _id: "67a1f8b8c898b6f0d1361ad5",
      ruleName: "Water Consumption Monitor",
      facility: [
        {
          _id: "65afd747a88851b5b987d272",
          name: "Production Facility A"
        }
      ],
      organization: "65afce3f68915901427b0b0e",
      scope: {
        _id: "64fc7ff893fd1158f639d016",
        name: "scope 1"
      },
      category: {
        _id: "64fff3ca1e3b32af1f756f64",
        name: "Process Emissions"
      },
      subcategory: {
        _id: "64fff9190e43038a5ef06561",
        name: "Water Treatment"
      },
      source: "WATER",
      subsource: "PROCESS",
      attribute: "CONSUMPTION",
      unit: {
        _id: "64fc026c5228cac66a909ae6",
        name: "m3"
      },
      ruleApplicability: "weekly",
      startYear: 2024,
      endYear: 2025,
      minValue: 100,
      minBuffer: 15,
      maxValue: 1000,
      maxBuffer: 20,
      blockOnAnomaly: true,
      status: "draft",
      isDeleted: false,
      resolvedCount: 12,
      unresolvedCount: 4,
      createdAt: "2024-10-20T09:15:00.000Z",
      updatedAt: "2024-10-20T09:15:00.000Z",
      __v: 0,
      anomalyDataCount: 16
    }
  ]
};

export const graphData = {
  success: true,
  message: "Data Fetched successfully",
  data: [
    {
      scope: "scope 1",
      months: {
        Jan: {
          anomalyCount: 0,
          totalEmission: 5200.42
        },
        Feb: {
          anomalyCount: 0,
          totalEmission: 4890.75
        },
        Mar: {
          anomalyCount: 2,
          totalEmission: 8120.33
        },
        Apr: {
          anomalyCount: 3,
          totalEmission: 5001.79
        },
        May: {
          anomalyCount: 0,
          totalEmission: 3190.99
        },
        Jun: {
          anomalyCount: 1,
          totalEmission: 4450.99
        },
        Jul: {
          anomalyCount: 0,
          totalEmission: 5235.05
        },
        Aug: {
          anomalyCount: 1,
          totalEmission: 7834.08
        },
        Sep: {
          anomalyCount: 0,
          totalEmission: 4510.12
        },
        Oct: {
          anomalyCount: 0,
          totalEmission: 3920.45
        },
        Nov: {
          anomalyCount: 1,
          totalEmission: 5780.33
        },
        Dec: {
          anomalyCount: 0,
          totalEmission: 4450.67
        }
      }
    },
    {
      scope: "scope 2",
      months: {
        Jan: {
          anomalyCount: 0,
          totalEmission: 3200.45
        },
        Feb: {
          anomalyCount: 0,
          totalEmission: 4950.21
        },
        Mar: {
          anomalyCount: 0,
          totalEmission: 4450.67
        },
        Apr: {
          anomalyCount: 0,
          totalEmission: 3920.89
        },
        May: {
          anomalyCount: 0,
          totalEmission: 3880.45
        },
        Jun: {
          anomalyCount: 2,
          totalEmission: 6160.78
        },
        Jul: {
          anomalyCount: 1,
          totalEmission: 4120.34
        },
        Aug: {
          anomalyCount: 0,
          totalEmission: 3880.63
        },
        Sep: {
          anomalyCount: 0,
          totalEmission: 4640.89
        },
        Oct: {
          anomalyCount: 0,
          totalEmission: 3190.45
        },
        Nov: {
          anomalyCount: 0,
          totalEmission: 4814.8
        },
        Dec: {
          anomalyCount: 2,
          totalEmission: 2980.67
        }
      }
    },
    {
      scope: "scope 3",
      months: {
        Jan: {
          anomalyCount: 0,
          totalEmission: 3887.29
        },
        Feb: {
          anomalyCount: 1,
          totalEmission: 4246.13
        },
        Mar: {
          anomalyCount: 2,
          totalEmission: 3850.45
        },
        Apr: {
          anomalyCount: 0,
          totalEmission: 3980.78
        },
        May: {
          anomalyCount: 1,
          totalEmission: 5590.34
        },
        Jun: {
          anomalyCount: 0,
          totalEmission: 4850.67
        },
        Jul: {
          anomalyCount: 0,
          totalEmission: 5135.56
        },
        Aug: {
          anomalyCount: 1,
          totalEmission: 3608.08
        },
        Sep: {
          anomalyCount: 0,
          totalEmission: 4505.64
        },
        Oct: {
          anomalyCount: 1,
          totalEmission: 5220.45
        },
        Nov: {
          anomalyCount: 2,
          totalEmission: 4161.53
        },
        Dec: {
          anomalyCount: 0,
          totalEmission: 3880.89
        }
      }
    }
  ]
};

export const anomalySummary = {
  data: [
    {
      scope: "Scope 1",
      category: "Stationary Combustion",
      subcategory: "Gaseous Fuels"
    },
    {
      scope: "Scope 1",
      category: "Purchased Electricity",
      subcategory: "Grid Electricity"
    },
    {
      scope: "Scope 1",
      category: "Purchased Electricity",
      subcategory: "Renewable Power"
    },
    {
      scope: "Scope 2",
      category: "Purchased Electricity",
      subcategory: "Grid Electricity"
    },
    {
      scope: "Scope 2",
      category: "Purchased Electricity",
      subcategory: "Renewable Power"
    },
    {
      scope: "Scope 3",
      category: "Business Travel",
      subcategory: "Air Travel"
    },
    {
      scope: "Scope 3",
      category: "Business Travel",
      subcategory: "Marine Shipping"
    },
    {
      scope: "Scope 3",
      category: "Business Travel",
      subcategory: "Air Travel"
    },
    {
      scope: "Scope 3",
      category: "Business Travel",
      subcategory: "Air Travel"
    }
  ]
};

