import React from "react";
import "./DefaultButton.scss";

const DefaultButton = ({ children, onClick, fontSize, disable, style }) => {
  return (
    <button
      className={`${disable ? "custom-button-disable" : "custom-button-default"}`}
      onClick={onClick}
      disabled={disable}
      style={{ fontSize, ...style }}
    >
      {children}
    </button>
  );
};

export default DefaultButton;
