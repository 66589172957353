import React, { useEffect, useState } from "react";
import "./DownloadSnackbar.scss";

const DownloadSnackbar = ({ open, onClose, fileName, isComplete, type = "download", autoHideDuration = 5000 }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(open);

  useEffect(() => {
    setSnackbarOpen(open);
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [open, onClose, autoHideDuration]);

  if (!snackbarOpen) return null;

  const getTitle = () => {
    if (isComplete) {
      switch (type) {
        case "download":
          return "Downloaded";
        case "create":
          return "Folder created!";
        case "delete":
          return "Item moved to trash";
        case "trash":
          return "Trash emptied";
        case "restore":
          return "Item restored";
        case "permanent delete":
          return "Item permanently deleted";
        case "create-anomaly-rule":
          return "Anomaly rule configured";
        case "update-anomaly-rule":
          return "Anomaly rule updated";
        case "delete-anomaly-rule":
          return "Anomaly rule deleted";
        default:
          return "Uploaded";
      }
    }
    
    return type ? type[0].toUpperCase() + type.slice(1) + "ing" : "Processing";
  };
  

  return (
    <div className="download-snackbar">
      <div className="download-content">
        {isComplete ? (
          <div className="success-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="10" fill="#38CB24" />
              <path
                d="M16.5 9L10.5 15L7.5 12"
                stroke="white"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        ) : (
          <div className="spinner" />
        )}
        <div className="download-text">
          <span className="download-text-title">{getTitle()}</span>
          <span className="download-text-file-name">{fileName}</span>
        </div>
      </div>
      <div className="download-close-button">
        <button className="close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M8 16L16 8M16 16L8 8"
              stroke="white"
              stroke-opacity="0.9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default DownloadSnackbar;
