import React from "react";
import "./Modal.scss";

import { Backdrop, Box, Modal } from "@mui/material";

const CustomModal = ({ modalOpen, setModalOpen, children, outSideClose, isReport }) => {
  let style = {};
  if (isReport) {
    style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: "10px",
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
      maxHeight: "90vh",
      maxWidth: "90vw",
      overflowY: "overlay",
      pb: "24px",
      fontFamily: "'Poppins', sans-serif"
    };
  } else {
    style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: "10px",
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
      maxHeight: "90vh",
      maxWidth: "90vw",
      overflowY: "overlay",
      pt: "24px",
      px: "24px",
      pb: "24px",
      fontFamily: "'Poppins', sans-serif",
      overflowX: "hidden"
    };
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && !outSideClose) {
      return;
    } else if (reason && reason === "backdropClick" && outSideClose) {
      setModalOpen(false);
    } else {
      setModalOpen(false);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition // Close modal after transition (animation)
      BackdropComponent={Backdrop} // Ensure Backdrop is rendered
      BackdropProps={{ onClick: handleClose }} // Handle Backdrop click
    >
      <Box sx={{ ...style }}>
        <div className="scrollBox">{children}</div>
      </Box>
    </Modal>
  );
};

export default React.memo(CustomModal);
