import { combineReducers, configureStore } from "@reduxjs/toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import globalReducer from "./state";
import { api } from "./state/api";
import brsrReportReducer from "./state/brsrReportSlice";
import multipleCategoryReducer from "./state/multiplCategorySlice";
import socialReducer from "./state/SocialSlice";
import governanceReducer from "./state/govtSlice";
import esgTabsReducer from "./state/esgTabsSlice";
import reportVariableReducer from "./state/reportVariablesSlice";
import * as Sentry from "@sentry/react";
import corruptionReducer from "./state/antiCorruptionSlice";
import peerAnalysisReducer from "./state/peerAnalysisSlics";
import anomalyReducer from "./state/anomalySlice";


Sentry.init({
  dsn: "https://50e0c3eeff198b1c9a8176f7c6a05249@o4508676990173184.ingest.us.sentry.io/4508681743171584",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const preloadedState = {};

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["global", "reportVariable"]
// };

// const rootReducer = combineReducers({
//   global: globalReducer,
//   persistedReduceReportV: reportVariableReducer
// });

// const persistedReducerGlobal = persistReducer(persistConfig, rootReducer);

const globalPersistConfig = {
  key: "global",
  storage,
};

const reportVariablePersistConfig = {
  key: "reportVariable",
  storage,
};

const persistedGlobalReducer = persistReducer(globalPersistConfig, globalReducer);
const persistedReportVariableReducer = persistReducer(reportVariablePersistConfig, reportVariableReducer);

const store = configureStore({
  reducer: {
    global: persistedGlobalReducer,
    category: multipleCategoryReducer,
    report: brsrReportReducer,
    social: socialReducer,
    governance: governanceReducer,
    esgTabs: esgTabsReducer,
    corrupt: corruptionReducer,
    reportVariable: persistedReportVariableReducer,
    peer: peerAnalysisReducer,
    anomaly: anomalyReducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState
});

export const persistor = persistStore(store);

// persistor.purge();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
