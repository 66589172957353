import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import SecondaryButton from "../../../components/Buttons/PrimaryButton/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import {
  usePostAddNotesMutation,
  usePostDeleteNotesMutation,
  useLazyGetNotesHistoryQuery,
  usePostEditFinalNoteMutation,
  useLazyGetNotesFacilityQuery
} from "../../../state/api";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import { useSelector } from "react-redux";
import "./BRSRNote.scss";
import { getDisplayValue, timeAgo } from "../../../helper/Utils/utilitiyFunctions";
import { Drawer, Avatar, Button } from "@mui/material";
import WidgetLoader from "../../../components/Loaders/WidgetLoader";
import { Select } from "antd";
import CustomModal from "../../../components/Modal/Modal";

const BRSRNote = ({
  data,
  questionId,
  questionData,
  activeQuestionId,
  setActiveQuestionId,
  role,
  ans,
  finalNote,
  answerType
}) => {
  const { openSnackbar } = useSnackbar();
  const [notesValue, setNotesValue] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [noteHistoryData, setNoteHistoryData] = useState([]);
  const [facFilter, setFacFilter] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [textStatus, setTextStatus] = useState({
    length: 0,
    isOverLimit: false,
    isEmpty: true
  });

  const [postAddNotes] = usePostAddNotesMutation();
  const [postDeleteNotes] = usePostDeleteNotesMutation();
  const [getNotesHistory, notesHistoryResult] = useLazyGetNotesHistoryQuery();
  const [postEditFinalNote] = usePostEditFinalNoteMutation();
  const [getNotesFacility, notesFacilityResult] = useLazyGetNotesFacilityQuery();

  const maxCharacters = 1500;

  const facilityId = role === "reviewer/approver" || role === "Admin" ? ans?.facilityId?._id : data?.facId;

  // Determine if the approver can edit notes
  const canApproverEdit = role === "reviewer/approver" && ans?.isApproved === true && ans?.isAccepted === false;

  // Determine if the admin can edit notes
  const canAdminEdit = role === "Admin" && finalNote?.notes ? true : false;

  // Check if this is a merged answer with a final note
  const isMergedAnswer = (role === "Admin" && questionData?.finalAnswer) || finalNote?.notes;
  const noteToDisplay = finalNote?.notes ? finalNote : answerType === "multiple" ? ans?.note : questionData?.note;

  // Check if this component should show the editor based on activeQuestionId
  useEffect(() => {
    if (activeQuestionId === questionId) {
      setShowEditor(true);
    } else {
      setShowEditor(false);
    }
  }, [activeQuestionId, questionId]);

  useEffect(() => {
    if (role === "Admin") {
      getNotesFacility({ reportId: data.reportId, questionId: questionId });
    }
  }, [role]);

  useEffect(() => {
    if (notesFacilityResult?.data?.data) {
      setFacFilter(notesFacilityResult?.data?.data[0]?._id);
    }
  }, [notesFacilityResult?.data?.data]);

  const handleGetNotes = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setNotesData([]);
    }, 1000);
  };

  useEffect(() => {
    if (isEditMode && noteToDisplay) {
      setNotesValue(noteToDisplay.notes);
    } else if (!isEditMode && !showEditor) {
      // Only reset notes value when not in edit mode and not showing editor
      setNotesValue("");
    }
  }, [isEditMode, questionData, showEditor, noteToDisplay]);

  const handleNotesChange = (e) => {
    setNotesValue(e.target.value);
  };

  const handleLengthChange = (status) => {
    setTextStatus(status);
  };

  const handleSubmit = async () => {
    if (textStatus.isEmpty) {
      openSnackbar("Please enter some notes", "warning");
      return;
    }
    
    if (textStatus.isOverLimit) {
      openSnackbar(`Notes should not exceed ${maxCharacters} characters`, "warning");
      return;
    }
    
    setIsLoading(true);
    try {
      // If this is a merged answer and we're editing a final note, use the editFinalNote API
      if (isMergedAnswer) {
        const body = {
          questionId: questionId,
          // facilityId: facilityId,
          reportId: data.reportId,
          finalNote: notesValue
        };
        const res = await postEditFinalNote(body);

        if (res?.data?.success) {
          setNotesValue("");
          handleGetNotes();
          openSnackbar(res?.data?.message || "Final note updated successfully", "success");
          setShowEditor(false);
          setIsEditMode(false);
          if (setActiveQuestionId) {
            setActiveQuestionId(null);
          }
        } else {
          openSnackbar("Failed to update final note", "warning", 5000);
          setShowEditor(false);
          if (setActiveQuestionId) {
            setActiveQuestionId(null);
          }
        }
      } else {
        // Regular note handling
        const body = {
          questionId: questionId,
          facilityId: facilityId,
          reportId: data.reportId,
          notes: notesValue
        };
        const res = await postAddNotes(body);

        if (res?.data?.success) {
          setNotesValue("");
          handleGetNotes();
          openSnackbar(isEditMode ? "Note Updated Successfully" : res?.data?.message, "success");
          setShowEditor(false);
          setIsEditMode(false);
          if (setActiveQuestionId) {
            setActiveQuestionId(null);
          }
        } else {
          openSnackbar(isEditMode ? "Failed to update note" : "Failed to add notes", "warning", 5000);
          setShowEditor(false);
          if (setActiveQuestionId) {
            setActiveQuestionId(null);
          }
        }
      }
    } catch (error) {
      console.log(error);
      openSnackbar("An error occurred", "error");
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShowEditor(false);
    setNotesValue("");
    setIsEditMode(false);
    if (setActiveQuestionId) {
      setActiveQuestionId(null);
    }
  };

  const handleEditNote = () => {
    setIsEditMode(true);
    setShowEditor(true);
    // Set the notes value immediately when edit is clicked
    if (noteToDisplay) {
      setNotesValue(noteToDisplay.notes);
    }
    if (setActiveQuestionId) {
      setActiveQuestionId(questionId);
    }
  };

  const handleHistoryView = () => {
    setHistoryDrawerOpen(true);
  };

  const toggleHistoryDrawer = () => {
    setHistoryDrawerOpen(!historyDrawerOpen);
  };

  const handleDeleteClick = () => {
    setDeleteModal(true);
  };

  const deleteThisNote = async () => {
    setDeleteModal(false);
    setIsLoading(true);
    try {
      const body = {
        id: questionData?.note?._id
      };
      const res = await postDeleteNotes(body);

      if (res?.data?.success) {
        handleGetNotes();
        openSnackbar(res?.data?.message, "success");
        setShowEditor(false);
        setIsEditMode(false);
        if (setActiveQuestionId) {
          setActiveQuestionId(null);
        }
      } else {
        openSnackbar("Failed to delete note", "warning", 5000);
        setShowEditor(false);
        if (setActiveQuestionId) {
          setActiveQuestionId(null);
        }
      }
    } catch (error) {
      console.log(error);
      openSnackbar("An error occurred", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (historyDrawerOpen && data) {
      getNotesHistory({
        facilityId: facilityId || facFilter,
        reportId: data.reportId,
        questionId: questionId
      });
    }
  }, [historyDrawerOpen, data, facFilter, facilityId]);

  useEffect(() => {
    if (notesHistoryResult?.data?.data) {
      setNoteHistoryData(notesHistoryResult?.data?.data);
    }
  }, [notesHistoryResult?.data?.data]);

  // Simplified methods that use textStatus directly without fallbacks
  const isRichTextEmpty = () => {
    return textStatus.isEmpty;
  };

  const isRichTextOverLimit = () => {
    return textStatus.isOverLimit;
  };

  // Don't render anything if there's no note and not showing the editor
  if (!noteToDisplay && !showEditor) {
    return null;
  }

  return (
    <div className="brsr-note-container" style={{ width: "100%" }}>
      <CustomModal modalOpen={deleteModal} setModalOpen={setDeleteModal} outSideClose={false}>
        <div className="d-flex gap-3">
          <div className="red-circle-container">
            <img src="/images/icons/delete-icon.svg" alt="icon" />
          </div>

          <div style={{ maxWidth: "390px" }}>
            <div>
              <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                Delete note?
              </p>
              <p className="sub-heading-text" style={{ fontSize: "14px", textAlign: "start" }}>
                Are you sure you want to delete this note?
              </p>
            </div>

            <div className="d-flex gap-4 mt-3">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => setDeleteModal(false)}
                style={{ flex: 1 }}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger" style={{ flex: 1 }} onClick={() => deleteThisNote()}>
                Delete
              </button>
            </div>
          </div>

          <div className="close-icon-placement" style={{}} onClick={() => setDeleteModal(false)}>
            <img src="/images/icons/cross-icon.svg" alt="icon" className="cursor-pointer x-icon" />
          </div>
        </div>
      </CustomModal>
      <div style={{ position: "relative" }} className="note-history-drawer">
        <Drawer open={historyDrawerOpen} onClose={toggleHistoryDrawer} anchor="right">
          <div className="p-4 note-history-drawer">
            <p className="note-history-title">Note history</p>

            {role === "Admin" && (
              <div className="d-flex justify-content-between" style={{ marginBottom: "10px", marginTop: "24px" }}>
                <div className="select-facility-text">Select facility</div>
                <Select
                  value={facFilter}
                  placeholder="Select Facility"
                  optionFilterProp="facility"
                  size="medium"
                  onChange={(newValue) => setFacFilter(newValue)}
                  className="br-8"
                  style={{ minWidth: "80px", borderRadius: "2px" }}
                >
                  {notesFacilityResult?.data?.data?.map((item, ind) => (
                    <Select.Option key={ind} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}

            <div className="note-msg-container">
              {!notesHistoryResult?.isLoading ? (
                noteHistoryData?.length === 0 ? (
                  <div
                    className="empty-wrapper d-flex flex-column px-4 py-3 mt-3 mb-3"
                    style={{ height: "224px", background: "#F2F3F7" }}
                  >
                    <img src="/images/BRSR/maker-icon.svg" alt="info-icon" width={48} className="mb-2" />
                    <p className="subtitle-1">No note history found!</p>
                    <p className="caption-1-Regular">The history of all edits to this note will appear here.</p>
                  </div>
                ) : (
                  noteHistoryData?.length > 0 && (
                    <div>
                      {noteHistoryData.map((item, index) => (
                        <div key={index + item} className="mt-3">
                          <div className="profile-section d-flex gap-2">
                            <Avatar alt={item.name} src="/static/images/avatar/1.jpg" />
                            <div>
                              <p className="profile-name mb-1">{item.name}</p>
                              <p className="profile-role mb-0" style={{ color: "#16161EB2" }}>
                                {item?.role === "reviewer/approver" ? "Approver" : getDisplayValue(item?.role)}
                                <span className="profile-role-time">
                                  {" | " + "edited " + timeAgo(item.updatedAt || item.createdAt)}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="msg-container">
                            <ReactQuill
                              value={item.previousNote}
                              modules={{ toolbar: false }}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "4px",
                                marginTop: "10px",
                                border: "1px solid #E5E5E5"
                              }}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )
              ) : (
                <div className="empty-wrapper">
                  <WidgetLoader />
                </div>
              )}
            </div>
            <PrimaryButton onClick={toggleHistoryDrawer} style={{ borderRadius: "4px" }}>
              Close
            </PrimaryButton>
          </div>
        </Drawer>
      </div>

      {noteToDisplay && !showEditor && (
        <div className="inline-editor-container">
          <p className="subtitle-1" style={{ marginBottom: "16px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M7.73996 16.4708H7.07681C4.68356 16.4708 3.4862 16.4708 2.74273 15.702C1.99926 14.9333 2 13.6958 2 11.2208V7.47075C2 4.99575 2 3.75825 2.74421 2.99025C3.4862 2.22 4.68356 2.22 7.07681 2.22H9.25269C11.6467 2.22 13.0452 2.262 13.7887 3.03C14.5329 3.7995 14.5255 4.995 14.5255 7.47V8.36025M11.9068 1.5V3M8.22259 1.5V3M4.5384 1.5V3M5.31577 11.25H8.26312M5.31577 7.5H11.2105"
                stroke="#656575"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                opacity="0.93"
                d="M15.4547 11.1592C14.7871 10.398 14.387 10.4437 13.9427 10.5795C13.631 10.6245 12.5641 11.892 12.119 12.297C11.3888 13.0305 10.6549 13.7865 10.6071 13.8855C10.4685 14.1142 10.3403 14.5192 10.2777 14.9722C10.162 15.6517 9.99548 16.416 10.2062 16.482C10.4169 16.548 11.0072 16.422 11.674 16.3222C12.119 16.2397 12.43 16.1497 12.6525 16.014C12.9642 15.8235 13.5419 15.1537 14.5388 14.157C15.1629 13.4872 15.7656 13.0245 15.944 12.5722C16.1215 11.8927 15.8555 11.5305 15.4547 11.1592Z"
                stroke="#656575"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {"Note"}
          </p>
          <div className="note-content-wrapper">
            <ReactQuill
              value={noteToDisplay?.notes}
              modules={{ toolbar: false }}
              style={{
                minHeight: "50px",
                height: "auto",
                background: "#FFFFFF",
                borderRadius: "0px",
                borderTop: "1px solid #C6CBD9",
                borderBottom: "1px solid #C6CBD9",
                padding: "0px !important"
              }}
              readOnly={true}
            />

            {answerType === "single" ? (
              !questionData?.isApproved && !finalNote?.notes ? (
                <div className="note-actions">
                  <div className="note-info-container">
                    <div className="note-info-item">{noteToDisplay?.name}</div>
                    <div className="note-info-sub-item">
                      <p className="subtitle-2">
                        {noteToDisplay?.role === "reviewer/approver"
                          ? "Approver"
                          : getDisplayValue(noteToDisplay?.role)}
                      </p>
                      <p className="subtitle-3">|</p>
                      <p className="subtitle-3">edited {timeAgo(noteToDisplay?.updatedAt)}</p>
                    </div>
                  </div>
                  <div onClick={handleHistoryView} className="cursor-pointer">
                    <div className="notes-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                          d="M10 3C8.4087 3 6.88258 3.63214 5.75736 4.75736C4.63214 5.88258 4 7.4087 4 9H2L4.59333 11.5933L4.64 11.6867L7.33333 9H5.33333C5.33333 6.42 7.42 4.33333 10 4.33333C12.58 4.33333 14.6667 6.42 14.6667 9C14.6667 11.58 12.58 13.6667 10 13.6667C8.71333 13.6667 7.54667 13.14 6.70667 12.2933L5.76 13.24C6.31552 13.7988 6.97622 14.242 7.70396 14.5441C8.43169 14.8462 9.21206 15.0011 10 15C11.5913 15 13.1174 14.3679 14.2426 13.2426C15.3679 12.1174 16 10.5913 16 9C16 7.4087 15.3679 5.88258 14.2426 4.75736C13.1174 3.63214 11.5913 3 10 3ZM9.33333 6.33333V9.66666L12.1867 11.36L12.6667 10.5533L10.3333 9.16666V6.33333H9.33333Z"
                          fill="#1F93EF"
                        />
                      </svg>
                      <div className="content">
                        <p className="m-0">View history</p>
                      </div>
                    </div>
                  </div>
                  {role !== "reviewer/approver" && role !== "Admin" && (
                    <div onClick={handleEditNote} className="cursor-pointer">
                      <div className="notes-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                            d="M3 12.6403V14.6667C3 14.8534 3.14665 15 3.33329 15H5.35968C5.44633 15 5.53299 14.9667 5.59298 14.9L12.872 7.62767L10.3723 5.12801L3.09999 12.4004C3.03333 12.467 3 12.547 3 12.6403ZM14.8051 5.6946C14.8669 5.63293 14.9159 5.55968 14.9493 5.47904C14.9828 5.3984 15 5.31196 15 5.22466C15 5.13736 14.9828 5.05092 14.9493 4.97028C14.9159 4.88964 14.8669 4.81639 14.8051 4.75472L13.2453 3.19494C13.1836 3.13314 13.1104 3.08412 13.0297 3.05067C12.9491 3.01722 12.8626 3 12.7753 3C12.688 3 12.6016 3.01722 12.521 3.05067C12.4403 3.08412 12.3671 3.13314 12.3054 3.19494L11.0856 4.41477L13.5852 6.91443L14.8051 5.6946Z"
                            fill="#1F93EF"
                          />
                        </svg>
                        <div className="content">
                          <p className="m-0">Edit note</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {role !== "reviewer/approver" && role !== "Admin" && (
                    <div onClick={handleDeleteClick} className="cursor-pointer">
                      <div className="notes-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                          <path
                            d="M1.79932 4.29993H10.1993L9.59932 12.9999H2.39932L1.79932 4.29993Z"
                            stroke="#1F93EF"
                            stroke-width="1.2"
                            stroke-linejoin="round"
                          />
                          <path d="M6 5.79993V11.1999" stroke="#1F93EF" stroke-width="1.2" stroke-linejoin="round" />
                          <path
                            d="M3.89941 5.79993L4.19941 11.1999"
                            stroke="#1F93EF"
                            stroke-width="1.2"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.10059 5.79993L7.80059 11.1999"
                            stroke="#1F93EF"
                            stroke-width="1.2"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.79932 4.3C1.79932 3.30589 2.6052 2.5 3.59932 2.5H8.39932C9.39343 2.5 10.1993 3.30589 10.1993 4.3V4.3H1.79932V4.3Z"
                            stroke="#1F93EF"
                            stroke-width="1.2"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.79932 2.5V2.2C4.79932 1.53726 5.33657 1 5.99932 1V1C6.66206 1 7.19932 1.53726 7.19932 2.2V2.5"
                            stroke="#1F93EF"
                            stroke-width="1.2"
                            stroke-linejoin="round"
                          />
                          <path d="M0 4.29993H12" stroke="#1F93EF" stroke-width="1.2" stroke-linejoin="round" />
                        </svg>
                        <div className="content delete-content">
                          <p className="m-0">Delete note</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="note-actions">
                    <div className="note-info-container">
                      <div className="note-info-item">{noteToDisplay?.name}</div>
                      <div className="note-info-sub-item">
                        <p className="subtitle-2">
                          {noteToDisplay?.role === "reviewer/approver"
                            ? "Approver"
                            : getDisplayValue(noteToDisplay?.role)}
                        </p>
                        <p className="subtitle-3">|</p>
                        <p className="subtitle-3">edited {timeAgo(noteToDisplay?.updatedAt)}</p>
                      </div>
                    </div>
                    <div onClick={handleHistoryView} className="cursor-pointer">
                      <div className="notes-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                            d="M10 3C8.4087 3 6.88258 3.63214 5.75736 4.75736C4.63214 5.88258 4 7.4087 4 9H2L4.59333 11.5933L4.64 11.6867L7.33333 9H5.33333C5.33333 6.42 7.42 4.33333 10 4.33333C12.58 4.33333 14.6667 6.42 14.6667 9C14.6667 11.58 12.58 13.6667 10 13.6667C8.71333 13.6667 7.54667 13.14 6.70667 12.2933L5.76 13.24C6.31552 13.7988 6.97622 14.242 7.70396 14.5441C8.43169 14.8462 9.21206 15.0011 10 15C11.5913 15 13.1174 14.3679 14.2426 13.2426C15.3679 12.1174 16 10.5913 16 9C16 7.4087 15.3679 5.88258 14.2426 4.75736C13.1174 3.63214 11.5913 3 10 3ZM9.33333 6.33333V9.66666L12.1867 11.36L12.6667 10.5533L10.3333 9.16666V6.33333H9.33333Z"
                            fill="#1F93EF"
                          />
                        </svg>
                        <div className="content">
                          <p className="m-0">View history</p>
                        </div>
                      </div>
                    </div>
                    {(canApproverEdit || canAdminEdit || isMergedAnswer) && (
                      <div onClick={handleEditNote} className="cursor-pointer">
                        <div className="notes-container">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M3 12.6403V14.6667C3 14.8534 3.14665 15 3.33329 15H5.35968C5.44633 15 5.53299 14.9667 5.59298 14.9L12.872 7.62767L10.3723 5.12801L3.09999 12.4004C3.03333 12.467 3 12.547 3 12.6403ZM14.8051 5.6946C14.8669 5.63293 14.9159 5.55968 14.9493 5.47904C14.9828 5.3984 15 5.31196 15 5.22466C15 5.13736 14.9828 5.05092 14.9493 4.97028C14.9159 4.88964 14.8669 4.81639 14.8051 4.75472L13.2453 3.19494C13.1836 3.13314 13.1104 3.08412 13.0297 3.05067C12.9491 3.01722 12.8626 3 12.7753 3C12.688 3 12.6016 3.01722 12.521 3.05067C12.4403 3.08412 12.3671 3.13314 12.3054 3.19494L11.0856 4.41477L13.5852 6.91443L14.8051 5.6946Z"
                              fill="#1F93EF"
                            />
                          </svg>
                          <div className="content">
                            <p className="m-0">Edit note</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )
            ) : !ans?.isApproved ? (
              <div className="note-actions">
                <div className="note-info-container">
                  <div className="note-info-item">{noteToDisplay?.name}</div>
                  <div className="note-info-sub-item">
                    <p className="subtitle-2">
                      {noteToDisplay?.role === "reviewer/approver" ? "Approver" : getDisplayValue(noteToDisplay?.role)}
                    </p>
                    <p className="subtitle-3">|</p>
                    <p className="subtitle-3">edited {timeAgo(noteToDisplay?.updatedAt)}</p>
                  </div>
                </div>
                <div onClick={handleHistoryView} className="cursor-pointer">
                  <div className="notes-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M10 3C8.4087 3 6.88258 3.63214 5.75736 4.75736C4.63214 5.88258 4 7.4087 4 9H2L4.59333 11.5933L4.64 11.6867L7.33333 9H5.33333C5.33333 6.42 7.42 4.33333 10 4.33333C12.58 4.33333 14.6667 6.42 14.6667 9C14.6667 11.58 12.58 13.6667 10 13.6667C8.71333 13.6667 7.54667 13.14 6.70667 12.2933L5.76 13.24C6.31552 13.7988 6.97622 14.242 7.70396 14.5441C8.43169 14.8462 9.21206 15.0011 10 15C11.5913 15 13.1174 14.3679 14.2426 13.2426C15.3679 12.1174 16 10.5913 16 9C16 7.4087 15.3679 5.88258 14.2426 4.75736C13.1174 3.63214 11.5913 3 10 3ZM9.33333 6.33333V9.66666L12.1867 11.36L12.6667 10.5533L10.3333 9.16666V6.33333H9.33333Z"
                        fill="#1F93EF"
                      />
                    </svg>
                    <div className="content">
                      <p className="m-0">View history</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="note-actions">
                  <div className="note-info-container">
                    <div className="note-info-item">{noteToDisplay?.name}</div>
                    <div className="note-info-sub-item">
                      <p className="subtitle-2">
                        {noteToDisplay?.role === "reviewer/approver"
                          ? "Approver"
                          : getDisplayValue(noteToDisplay?.role)}
                      </p>
                      <p className="subtitle-3">|</p>
                      <p className="subtitle-3">edited {timeAgo(noteToDisplay?.updatedAt)}</p>
                    </div>
                  </div>
                  <div onClick={handleHistoryView} className="cursor-pointer">
                    <div className="notes-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                          d="M10 3C8.4087 3 6.88258 3.63214 5.75736 4.75736C4.63214 5.88258 4 7.4087 4 9H2L4.59333 11.5933L4.64 11.6867L7.33333 9H5.33333C5.33333 6.42 7.42 4.33333 10 4.33333C12.58 4.33333 14.6667 6.42 14.6667 9C14.6667 11.58 12.58 13.6667 10 13.6667C8.71333 13.6667 7.54667 13.14 6.70667 12.2933L5.76 13.24C6.31552 13.7988 6.97622 14.242 7.70396 14.5441C8.43169 14.8462 9.21206 15.0011 10 15C11.5913 15 13.1174 14.3679 14.2426 13.2426C15.3679 12.1174 16 10.5913 16 9C16 7.4087 15.3679 5.88258 14.2426 4.75736C13.1174 3.63214 11.5913 3 10 3ZM9.33333 6.33333V9.66666L12.1867 11.36L12.6667 10.5533L10.3333 9.16666V6.33333H9.33333Z"
                          fill="#1F93EF"
                        />
                      </svg>
                      <div className="content">
                        <p className="m-0">View history</p>
                      </div>
                    </div>
                  </div>
                  {(canApproverEdit || canAdminEdit || isMergedAnswer) && (
                    <div onClick={handleEditNote} className="cursor-pointer">
                      <div className="notes-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                            d="M3 12.6403V14.6667C3 14.8534 3.14665 15 3.33329 15H5.35968C5.44633 15 5.53299 14.9667 5.59298 14.9L12.872 7.62767L10.3723 5.12801L3.09999 12.4004C3.03333 12.467 3 12.547 3 12.6403ZM14.8051 5.6946C14.8669 5.63293 14.9159 5.55968 14.9493 5.47904C14.9828 5.3984 15 5.31196 15 5.22466C15 5.13736 14.9828 5.05092 14.9493 4.97028C14.9159 4.88964 14.8669 4.81639 14.8051 4.75472L13.2453 3.19494C13.1836 3.13314 13.1104 3.08412 13.0297 3.05067C12.9491 3.01722 12.8626 3 12.7753 3C12.688 3 12.6016 3.01722 12.521 3.05067C12.4403 3.08412 12.3671 3.13314 12.3054 3.19494L11.0856 4.41477L13.5852 6.91443L14.8051 5.6946Z"
                            fill="#1F93EF"
                          />
                        </svg>
                        <div className="content">
                          <p className="m-0">Edit note</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {showEditor && (
        <div className="inline-editor-container">
          <p className="subtitle-1" style={{ marginBottom: "16px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M7.73996 16.4708H7.07681C4.68356 16.4708 3.4862 16.4708 2.74273 15.702C1.99926 14.9333 2 13.6958 2 11.2208V7.47075C2 4.99575 2 3.75825 2.74421 2.99025C3.4862 2.22 4.68356 2.22 7.07681 2.22H9.25269C11.6467 2.22 13.0452 2.262 13.7887 3.03C14.5329 3.7995 14.5255 4.995 14.5255 7.47V8.36025M11.9068 1.5V3M8.22259 1.5V3M4.5384 1.5V3M5.31577 11.25H8.26312M5.31577 7.5H11.2105"
                stroke="#656575"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                opacity="0.93"
                d="M15.4547 11.1592C14.7871 10.398 14.387 10.4437 13.9427 10.5795C13.631 10.6245 12.5641 11.892 12.119 12.297C11.3888 13.0305 10.6549 13.7865 10.6071 13.8855C10.4685 14.1142 10.3403 14.5192 10.2777 14.9722C10.162 15.6517 9.99548 16.416 10.2062 16.482C10.4169 16.548 11.0072 16.422 11.674 16.3222C12.119 16.2397 12.43 16.1497 12.6525 16.014C12.9642 15.8235 13.5419 15.1537 14.5388 14.157C15.1629 13.4872 15.7656 13.0245 15.944 12.5722C16.1215 11.8927 15.8555 11.5305 15.4547 11.1592Z"
                stroke="#656575"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {isMergedAnswer ? "Edit Final Note" : isEditMode ? "Edit Note" : "Add Note"}
          </p>
          <RichTextEditor
            value={notesValue}
            onChange={handleNotesChange}
            maxCharacters={maxCharacters}
            placeholder="Write your notes here..."
            name="notes"
            onLengthChange={handleLengthChange}
          />
          {isRichTextOverLimit() && (
            <p className="out-of-limit-text">
              {`Your notes have exceeded the ${maxCharacters}-character limit. Please shorten them`}
            </p>
          )}
          <div className="editor-actions">
            <SecondaryButton onClick={handleCancel} style={{ marginRight: "10px" }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton 
              onClick={handleSubmit} 
              loading={isLoading} 
              disabled={isRichTextEmpty() || isRichTextOverLimit()}
            >
              {isEditMode || isMergedAnswer ? "Update" : "Submit"}
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BRSRNote;
