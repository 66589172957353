import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../../App.scss";
import "./Layout.scss";

import {
  api,
  useGetOrganizationDataQuery,
  useLazyGetUserDetailByIdQuery,
  usePostAuditLogDataMutation
} from "../../state/api";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dropdown, Menu } from "antd";
import { stringAvatar } from "../../helper/Utils/utilitiyFunctions";
import { logoutUser } from "../../state";
import { persistor } from "../..";
import { apiUrl } from "../ApiUrl";

const Layout = () => {
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [postAuditLogData] = usePostAuditLogDataMutation({});
  const orgData = useGetOrganizationDataQuery();

  const userId = useSelector((state) => state.global.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser({ id: userId?._id });
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);
  

  const logoutUserM = async () => {
    await fetch(`${apiUrl}/auth/logout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    });
  };

  const logout = async () => {
    await postAuditLogData({
      action: "USER VALIDATION",
      message: `User logged out`,
      tab: "Users",
      facility: "",
      organization: orgData.data?.data._id,
      user: userId._id,
      ipAddress: "10.10.0.1"
    });

    dispatch(logoutUser());
    dispatch(api.util.resetApiState());
    persistor.purge();
    logoutUserM();
    navigate("/");
  };

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      logout();
    }
  };

  const logoutMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Logout</Menu.Item>
    </Menu>
  );

  return (
    <div className="mainDashContainer">
      <Sidebar />
      <div
        className="rightSideContainer scrollbar-customp"
        style={(location.pathname.includes("peer") || location.pathname.includes("anomaly") || location.pathname.includes("measure") || location.pathname.includes("waste") || location.pathname.includes("water") || location.pathname.includes("energy") || location.pathname.includes("airemission")) ? { background: "white" } : {}}
      >
        {/*------------------------HEADER START-------------------------*/}
        <div className="header-container">
          <div>
            <div className="company-logo-cont">
              {user?.organization && <img src={user?.organization?.logo} alt="iocn" className="custom-img-fluid" />}
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div className="logout-cont">
              {user?.name && <Avatar {...stringAvatar(user?.name)} style={{ background: "#1F93EF" }} />}
              {/* <div className="d-flex flex-column">
                  <p className="user-name">{user?.name}</p>
                  <CustomTooltip
                    key={"tooltip"}
                    content={`<p>${user?.email}</p>`}
                    showIcon={true}
                    id={"facStatus-info"}
                    showContained={true}
                    childComponent={<p className="user-email cursor-pointer">{user?.email}</p>}
                  />
                </div> */}
              <Dropdown className="logout-drop-down" overlay={logoutMenu} placement="bottomLeft" trigger={["click"]}>
                {/* <img src="/images/icons/three-dots-latest.svg" alt="iocn" className="cursor-pointer" /> */}
                <div className="cursor-pointer">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.3333 9.16667L9.99996 12.5L6.66663 9.16667"
                      stroke="#16161E"
                      stroke-opacity="0.7"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*------------------------HEADER END-------------------------*/}
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            padding: location.pathname.includes("anomaly") ? "16px 0px 0px 0px" : "16px 32px 32px 32px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
