import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../components/snackbar/SnackbarContext";
import { apiUrl } from "../ApiUrl";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import "./UploadCard.scss";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import SecondaryButton from "../Buttons/PrimaryButton/SecondaryButton";

const UploadCard = ({ itemId, data, canSelect, onCardSelect, activeTabId, viewOnly }) => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const pathname = window.location.pathname;
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    {
      data.icon
        ? onCardSelect(data._id, data.icon, data.label, !isChecked, data.link)
        : onCardSelect(data._id, data.logo, data.label, !isChecked, data.link);
    }
  };

  const { openSnackbar } = useSnackbar();

  const defaultLogo = "/images/templateLogo/gaseous.png";
  const defaultLogoDisabled = "/images/templateLogo/disabled-icon.svg";
  const downloadTemplate = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const res = await fetch(`${apiUrl}/emission/templete/file?id=${data._id}`, {
        method: "GET",
        credentials: "include",
        signal
      });

      const contentDispositionHeader = res.headers.get("Content-Disposition");
      let fileName = contentDispositionHeader.split("filename=")[1]?.replace(/['"]/g, "");
      if (!fileName) fileName = "template.xlsx";
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      openSnackbar("Your template has been downloaded successfully", "success");
    } catch (error) {
      openSnackbar(JSON.stringify(error), "error");
    }
  };
  const goToAddData = () => {
    if (viewOnly) {
      return;
    }

    if (data.link) {
      // console.log("data link" ,`/${data.link}`, data)
      navigate(`/${data.link}`, { state: { data: data._id, title: data.label, image: data.icon, link: data.link } });
    } else {
      if (data.label === "Air Emission") {
        navigate("/airemission/measure/upload", { state: { data: data._id, image: data.logo, title: data.label } });
      } else if (data.label === "Water Waste") {
        navigate("/water/measure/upload", { state: { data: data._id, image: data.logo, title: data.label } });
      } else if (data.label === "Solid Waste") {
        navigate("/waste/measure/upload", { state: { data: data._id, image: data.logo, title: data.label } });
      } else if (data.label === "Purchased Electricity") {
        navigate("/energy/measure/upload", { state: { data: data._id, image: data.logo, title: data.label } });
      } else {
        navigate("/measure/upload", { state: { data: data._id, image: data.logo, title: data.label } });
      }
    }
  };

  return (
    <div
      key={itemId}
      className={`cardContainer ${data.isActive ? "" : "opacity-75 cursor-disabled disabledBackground"}`}
    >
      {canSelect && data.isActive && data.label !== "Fuel & Energy Related Activities" && (
        <div className="checkBoxContainer">
          <input
            data-testid="checkbox-list"
            className="checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={activeTabId.find((item) => item.id === data._id)}
          />
        </div>
      )}
      <div>
        <div className="sub">
          <div>
            {data.isActive ? (
              <div className="overflow-hidden" style={{ borderRadius: "9999px" }}>
                <img
                  src={
                    data?.logo
                      ? `/images/templateLogo/${data.logo}`
                      : data.icon
                      ? `/images/templateLogo/${data.icon}.svg`
                      : defaultLogo
                  }
                  alt="img"
                />
              </div>
            ) : (
              <img src={data?.logo ? `/images/templateLogo/${data.logo}` : defaultLogoDisabled} alt="img" />
            )}
          </div>
          <div>
            <div className="titleContainerCat">
              {pathname.includes("social") || pathname.includes("governance") ? (
                <CustomTooltip
                  content={data.label}
                  showIcon={true}
                  childComponent={<p className="subCategory">{data.label}</p>}
                  id={"modal-info-action"}
                  showContained={true}
                />
              ) : (
                <p className="subCategory">{data.label}</p>
              )}

              <p className="subLabelCard">{data.subLabel}</p>
            </div>
            <p className="cardDecription">{data.description}</p>
            <div className="description">
              {!canSelect ? (
                <div className="groupDownload">
                  {pathname.includes("social") || pathname.includes("governance") ? (
                    ""
                  ) : (
                    <>
                      {data.label !== "Fuel & Energy Related Activities" && (
                        <SecondaryButton
                          onClick={data.isActive ? downloadTemplate : null}
                          style={{ height: "28px", background: "white" }}
                          disabled={!data.isActive}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 8.5V10.25C1 11.3546 1.89543 12.25 3 12.25H11C12.1046 12.25 13 11.3546 13 10.25V8.5"
                                stroke="black"
                                stroke-opacity="0.6"
                                stroke-width="1.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7 0.25V8.5"
                                stroke="black"
                                stroke-opacity="0.6"
                                stroke-width="1.6"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10 4.75L7 8.5L4 4.75"
                                stroke="black"
                                stroke-opacity="0.6"
                                stroke-width="1.6"
                                stroke-linejoin="round"
                              />
                            </svg>{" "}
                            <p style={{ marginLeft: "8px" }}>Download Template</p>
                          </div>
                        </SecondaryButton>
                      )}
                    </>
                  )}

                  {data.label === "Fuel & Energy Related Activities" ? (
                    <div className="frame-wrapper">
                      <div className="scopeContainer-2">
                        <CustomTooltip
                          content={
                            '<div style="width: 295px; padding: 24px; background: transparent; box-shadow: 0px 4px 64px -4px rgba(0, 0, 0, 0.20); border-radius: 8px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex"><div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: flex"><div style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">We’ve automatically calculated it:</div></div><div style="width: 247px; color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Based on the information entered in the Scope 1 and Scope 2 categories, the emissions for this category are automatically determined.</div></div></div>'
                          }
                          showIcon={true}
                          width={"320px"}
                          childComponent={
                            <SecondaryButton disabled={true} style={{background: "transparent"}}>
                              Data is calculated automatically
                            </SecondaryButton>
                          }
                          id={"modal-info-action"}
                          showContained={true}
                          place={"right"}
                        />
                      </div>
                    </div>
                  ) : (
                    <DefaultButton onClick={data.isActive ? goToAddData : null} disabled={!data.isActive || viewOnly}  style={{height: "28px"}}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.5 9L13.5 9M9 13.5L9 4.5"
                          stroke="white"
                          stroke-opacity="0.9"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Add new data
                    </DefaultButton>
                  )}
                </div>
              ) : (
                <>
                  {data.label === "Fuel & Energy Related Activities" && (
                    <CustomTooltip
                      content={
                        '<div style="width: 295px; padding: 24px; background: transparent; box-shadow: 0px 4px 64px -4px rgba(0, 0, 0, 0.20); border-radius: 8px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex"><div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: flex"><div style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">We’ve automatically calculated it:</div></div><div style="width: 247px; color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Based on the information entered in the Scope 1 and Scope 2 categories, the emissions for this category are automatically determined.</div></div></div>'
                      }
                      showIcon={true}
                      width={"320px"}
                      childComponent={<DefaultButton disabled={true}>Data calculated automatically</DefaultButton>}
                      id={"modal-info-action"}
                      showContained={true}
                      place={"right"}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UploadCard);
