import React, { createContext, useContext, useState } from 'react';
import CustomSnackbar from './Snackbar'; // Import your Snackbar component
import DownloadSnackbar from './DownloadSnackbar';

const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(3000); // Default duration in milliseconds
  
  // Download snackbar state
  const [downloadSnackbarOpen, setDownloadSnackbarOpen] = useState(false);
  const [downloadFileName, setDownloadFileName] = useState('');
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);
  const [messageType, setMessageType] = useState("download");

  const openSnackbar = (message, severity = 'info', autoHideDuration = 3000) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarAutoHideDuration(autoHideDuration);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const showDownloadProgress = (fileName, type, autoHideDuration = 3000) => {
    setDownloadFileName(fileName);
    setMessageType(type);
    setIsDownloadComplete(false);
    setDownloadSnackbarOpen(true);
    setSnackbarAutoHideDuration(autoHideDuration);
  };

  const completeDownload = () => {
    setIsDownloadComplete(true);
  };

  const hideDownloadProgress = () => {
    setDownloadSnackbarOpen(false);
    setIsDownloadComplete(false);
  };

  return (
    <SnackbarContext.Provider value={{ 
      openSnackbar, 
      closeSnackbar, 
      showDownloadProgress,
      hideDownloadProgress,
      completeDownload
    }}>
      {children}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={snackbarAutoHideDuration}
      />
      <DownloadSnackbar
        open={downloadSnackbarOpen}
        onClose={hideDownloadProgress}
        fileName={downloadFileName}
        isComplete={isDownloadComplete}
        type={messageType}
        autoHideDuration={snackbarAutoHideDuration}
      />
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}
