import React, { useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

const RichTextEditor = ({ value, onChange, maxCharacters, placeholder, name, onLengthChange }) => {
  const quillRef = useRef(null);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [textLength, setTextLength] = useState(0);

  // Function to get plain text length without HTML tags
  const getTextLength = (html) => {
    if (!html || html === '<p><br></p>') return 0;
    
    if (quillRef.current && quillRef.current.getEditor) {
      return quillRef.current.getEditor().getText().trim().length;
    }
    
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent.trim().length;
  };

  const handleEditorChange = (content) => {
    const cleanContent = content === '<p><br></p>' ? '' : content;
    
    const actualTextLength = getTextLength(cleanContent);
    setTextLength(actualTextLength);
    const overLimit = actualTextLength > maxCharacters;
    setIsOverLimit(overLimit);
    
    // Pass length info to parent component if callback exists
    if (onLengthChange) {
      onLengthChange({
        length: actualTextLength,
        isOverLimit: overLimit,
        isEmpty: actualTextLength === 0
      });
    }

    onChange({
      target: {
        name: name,
        value: cleanContent
      }
    });
  };

  // Add effect to highlight text when over the limit
  useEffect(() => {
    if (quillRef.current && quillRef.current.getEditor) {
      const editor = quillRef.current.getEditor();
      const text = editor.getText();
      
      if (isOverLimit && text.length > maxCharacters) {
        // Apply normal style to text within limit
        editor.formatText(0, maxCharacters, { 
          color: '',
          strike: false
        });
        
        editor.formatText(maxCharacters, text.length, { 
          color: '#A6A6A6',
          strike: true
        });
      } else {
        editor.formatText(0, text.length, { 
          color: '',
          strike: false
        });
      }
    }
  }, [isOverLimit, value, maxCharacters]);

  useEffect(() => {
    const actualTextLength = getTextLength(value);
    setTextLength(actualTextLength);
    const overLimit = actualTextLength > maxCharacters;
    setIsOverLimit(overLimit);
    
    // Update parent on initial load and when value changes externally
    if (onLengthChange) {
      onLengthChange({
        length: actualTextLength,
        isOverLimit: overLimit,
        isEmpty: actualTextLength === 0
      });
    }
  }, [value, maxCharacters, onLengthChange]);

  return (
    <div className="custom-editor-container">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={handleEditorChange}
        placeholder={placeholder}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline", "strike"],
            ["link", "image", "code-block"],
            [{ align: [] }],
            ["clean"]
          ]
        }}
      />
      {/* Character Counter - Now showing actual text length */}
      <div className="word-counter">
        <span style={{ color: isOverLimit ? "red" : "inherit" }}>
          {textLength}/{maxCharacters}
        </span>
      </div>
    </div>
  );
};

export default RichTextEditor;
