import { createTheme } from "@mui/material";

export const Muitheme = createTheme({
  palette: {
    primary: {
      main: "#1F93EF"
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root": {
            fontWeight: "500",
            textTransform: "capitalize",
            fontFamily: "Poppins, sans-serif"
          },
          "& .Mui-selected": {
            color: "black !important"
          },
          "& .MuiTabs-indicator ": {
            backgroundColor: "#1F93EF",
            height: "3px"
          }
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        fontFamily: "'Poppins', sans-serif"
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 700,
          color: 'white',
          backgroundColor: "#1c1c1c",
          boxShadow: 'none'
        }
      }
    },

    MuiBox:{
      styleOverrides:{
        root:{
          fontFamily: "'Poppins', sans-serif !important"
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
          fontFamily: "'Poppins', sans-serif"
        },
        head: {
          fontWeight: "500",
          textTransform: "capitalize",
          borderBottom: "1px solid rgba(224, 224, 224, 1)"
        }
      }
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          textAlign: "left"
        }
      }
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          padding: "0px 0px 0px 0px"
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "0px",
          borderRadius: "10px",
          height: "40px"
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          border: "0px",
          borderRadius: "10px",
          height: "40px"
        }
      }
    }
  }
});
