export const AnomalyEndPoint = (build) => ({
  getExistingRule: build.query({
    query: (arg) => ({
      url: `anomaly/existingRule`,
      params: { ...arg }
    }),
    providesTags: ["AnomalyRules"]
  }),

  createConfiguration: build.mutation({
    query: (body) => ({
      url: "anomaly/create",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["AnomalyConfigurations"]
  }),

  getAllConfigurations: build.query({
    query: (arg) => ({
      url: "anomaly/all",
      params: { ...arg }
    }),
    providesTags: ["AnomalyConfigurations"]
  }),

  getConfigurationDetail: build.query({
    query: (id) => ({
      url: `anomaly/detail/${id}`
    }),
    providesTags: ["AnomalyConfigurations"]
  }),

  updateConfiguration: build.mutation({
    query: (body) => ({
      url: "anomaly/update",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["AnomalyConfigurations"]
  }),

  deleteConfiguration: build.mutation({
    query: (id) => ({
      url: `anomaly/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["AnomalyConfigurations"]
  }),

  deleteBulkConfiguration: build.mutation({
    query: (body) => ({
      url: "anomaly/delete",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["AnomalyConfigurations"]
  }),

  getAnomalyGraphData: build.query({
    query: (arg) => ({
      url: "anomaly/graph",
      params: { ...arg }
    }),
    providesTags: ["AnomalyGraph"]
  }),

  getAnomalyGraphReview: build.query({
    query: (arg) => ({
      url: "/anomaly/graph-review",
      params: { ...arg }
    }),
    providesTags: ["AnomalyGraphReview"]
  }),

  getCoreFields: build.query({
    query: (arg) => ({
      url: "anomaly/coreFields",
      params: { ...arg }
    }),
    providesTags: ["AnomalyCoreFields"]
  }),

  getSourceFields: build.query({
    query: (arg) => ({
      url: "anomaly/sourceFields",
      params: { ...arg }
    }),
    providesTags: ["AnomalySourceFields"]
  }),

  getHistoryExists: build.query({
    query: (arg) => ({
      url: "anomaly/history-exists",
      params: { ...arg }
    }),
    providesTags: ["AnomalyHistoryExists"]
  }),

  getAnomalyHistory: build.query({
    query: (arg) => ({
      url: "anomaly/history",
      params: { ...arg }
    }),
    providesTags: ["AnomalyHistory"]
  })
});

