import React, { useEffect, useState } from "react";
import UploadCard from "../../../components/UploadCard/UploadCard";
import { useLazyGetTemplateListQuery } from "../../../state/api";

import "../../Measure/Measure.scss";
import "./SelectCards.scss";
import SearchBar from "../../../components/BRSRComponets/SearchBar/SearchBar";
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton";
import SecondaryButton from "../../../components/Buttons/PrimaryButton/SecondaryButton";

const SelectCards = ({ addSelectedCards, cancelSelect, activeTabId }) => {
  // cancelSelect, activeTabId
  const [getTemplate, resultTemplate] = useLazyGetTemplateListQuery();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelect = (cardId, cardImage, cardTitle, isSelected) => {
    if (isSelected) {
      setSelectedCards([...selectedCards, { id: cardId, image: cardImage, title: cardTitle }]);
    } else {
      setSelectedCards(selectedCards.filter((item) => item.id !== cardId));
    }
  };

  const handleAddSelected = () => {
    addSelectedCards(selectedCards);
  };

  useEffect(() => {
    if (searchTerm) {
      getTemplate({ search: searchTerm });
    } else {
      getTemplate({});
    }
  }, [resultTemplate.data, searchTerm]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  return (
    <>
      <div className="measure-wrapper">
        <div className="measureTableHeader gap-2">
          <div style={{ fontWeight: "500", fontSize: "16px", marginBottom: "1rem" }}>Select emission source</div>
          <div className="d-flex flex-wrap gap-2">
            <div className="me-10">
              <SearchBar onSearch={handleSearchChange} placeHolder={"Search"} />
            </div>
            <div></div>
            <div className="buttonContainer">
              <DefaultButton onClick={handleAddSelected}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.5 9L13.5 9M9 13.5L9 4.5"
                    stroke="white"
                    stroke-opacity="0.9"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                Add Selected
              </DefaultButton>

              <SecondaryButton onClick={() => cancelSelect()} style={{ height: "100%", background: "transparent" }}>
                Cancel
              </SecondaryButton>
            </div>
          </div>
        </div>
        <div className="cardContentContainer">
          {resultTemplate.data?.data.map(
            (item, key) =>
              item.scope.name !== "air emission" && (
                <div className="measureCardContainer" key={item.scope.name}>
                  <div className="scopeDesc">
                    <p className="scopeDescriptionPara">{item.scope.name}</p>
                    <p className="descriptionOfScope">{item.scope.description}</p>
                  </div>
                  <div className="cardsContainer">
                    {item.templetes.map((subItem, subKey) => (
                      <UploadCard
                        key={subItem._id}
                        itemId={subItem._id}
                        data={subItem}
                        canSelect={true}
                        activeTabId={activeTabId}
                        onCardSelect={handleCardSelect}
                      />
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default SelectCards;
