export const facStateMap = [
  {
    state: "Rajasthan",
    emissionFactorForClinker: 530.72,
    emissionFactorForCkd: 453.58
  },
  {
    state: "Chhattisgarh",
    emissionFactorForClinker: 524.29,
    emissionFactorForCkd: 448.35
  },
  {
    state: "Madhya Pradesh",
    emissionFactorForClinker: 524.29,
    emissionFactorForCkd: 448.35
  },
  {
    state: "Gujarat",
    emissionFactorForClinker: 531.7,
    emissionFactorForCkd: 454.37
  },
  {
    state: "Maharashtra",
    emissionFactorForClinker: 531.18,
    emissionFactorForCkd: 453.95
  },
  {
    state: "Andhra Pradesh",
    emissionFactorForClinker: 533.02,
    emissionFactorForCkd: 455.44
  },
  {
    state: "Karnataka",
    emissionFactorForClinker: 534.58,
    emissionFactorForCkd: 456.71
  },
  {
    state: "Tamil Nadu",
    emissionFactorForClinker: 536.35,
    emissionFactorForCkd: 458.14
  },
  {
    state: "Kerala",
    emissionFactorForClinker: 534.7,
    emissionFactorForCkd: 456.81
  },
  {
    state: "Bihar",
    emissionFactorForClinker: 514.63,
    emissionFactorForCkd: 440.5
  },
  {
    state: "Haryana",
    emissionFactorForClinker: 530.75,
    emissionFactorForCkd: 453.6
  },
  {
    state: "Himachal Pradesh",
    emissionFactorForClinker: 530.75,
    emissionFactorForCkd: 453.6
  },
  {
    state: "Jammu and Kashmir",
    emissionFactorForClinker: 531.1,
    emissionFactorForCkd: 453.89
  },
  {
    state: "Uttar Pradesh",
    emissionFactorForClinker: 514.63,
    emissionFactorForCkd: 440.5
  },
  {
    state: "Odisha",
    emissionFactorForClinker: 516.79,
    emissionFactorForCkd: 442.26
  },
  {
    state: "Assam",
    emissionFactorForClinker: 516.79,
    emissionFactorForCkd: 442.26
  },
  {
    state: "All",
    emissionFactorForClinker: 528.03,
    emissionFactorForCkd: 451.4
  }
];
