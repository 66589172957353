import React from "react";
import ReportStatus from "../ReportStatus/ReportStatus";
import "../../../App.scss";

const BRSRStatus = ({ obj }) => {
  return obj.contributors[0].isRejected ||
    obj.contributors[0].isAdminRejected ||
    obj.contributors[0]?.isApproved ||
    obj.contributors[0]?.isAccepted ||
    obj.contributors[0]?.isSubmitted ||
    obj.contributors[0]?.isAnswered ? (
    <div className="d-flex justify-content-between gap-2 mt-2">
      <div className="d-flex align-items-center gap-2">
        <div>
          <p className="caption-1-Regular m-0">Status</p>
        </div>
        <div>
          <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
            {obj.contributors[0].isRejected ? (
              <div className="d-flex align-items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                  <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                </svg>
                <p className="caption-1-Emphasized">Rejected</p>
              </div>
            ) : obj.contributors[0].isAdminRejected ? (
              <div className="d-flex align-items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                  <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                </svg>
                <p className="caption-1-Emphasized">
                  Rejected by <span className="caption-1-Regular">Admin</span>
                </p>
              </div>
            ) : obj.contributors[0].isAccepted ? (
              <div className="d-flex align-items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                    fill="#38CB24"
                  />
                </svg>
                <p className="caption-1-Emphasized">Accepted</p>
              </div>
            ) : obj.contributors[0].isApproved ? (
              <div className="d-flex align-items-center gap-1">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="7" fill="#50CFAB" />
                  <path
                    d="M12 7L8 11L6 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="caption-1-Emphasized">Approved</p>
              </div>
            ) : obj.contributors[0].isSubmitted ? (
              <div className="d-flex align-items-center gap-1">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="7" fill="#1F93EF" />
                  <path
                    d="M12 7L8 11L6 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="caption-1-Emphasized">Submitted</p>
              </div>
            ) : obj.contributors[0].isAnswered ? (
              <div className="d-flex align-items-center gap-1">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="7" fill="#1C72FF" />
                  <path
                    d="M12 7L8 11L6 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="caption-1-Emphasized">Answered</p>
              </div>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex justify-content-end mt-2">
      <ReportStatus />
    </div>
  );
};

export default BRSRStatus;
